<template>
  <NotFoundPage />
</template>

<script>
import NotFoundPage from "@/components/NotFound.vue";
export default {
  components: {
    NotFoundPage
  },
  created() {
    this.updateTitle("NOT FOUND");
  }
};
</script>
